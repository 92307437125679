.theme-style-wrapper.theme--dark {
    &.theme--futura {
        color: $tsWhiteText;
        background: $tsDarkGreenBackground;

        .screen-footer {
            color: $tsWhiteText;
        }

        .screen-content {
            div {
                border-color: $tsDarkModeBorder !important;
            }
        }

        .border-element .piece {
            background: $tsDarkModeBorder;
        }

        .block-logos {
            &::after {
                background: $tsDarkGreenBackground;
            }
        }

        .screen-content__main {
            background: $tsDarkGreenBackground;
            &::after {
                border-color: $tsDarkModeBorder;
                background: $tsDarkGreenBackground;
            }
        }

        .content-container__header {
            border-color: $tsDarkModeRowBorder;
        }

        .content-container__row {
            background: $tsDarkGreenBackground;
            border-color: $tsDarkModeRowBorder;
            &.building-directory__row {
                background: none;
            }
        }

        .screen-logo {
            svg {
                polyline {
                    stroke: $tsLightModeBorder;
                }

                path {
                    fill: $tsWhite;
                }
            }
        }

        .masstransit__header__title {
            color: $tsWhiteText;
        }

        .slide-wrap .control-dots .dot {
            background: $tsWhiteText;
        }

        .community-message {
            .slide-wrap .control-dots .dot {
                background: $tsWhiteText;
            }
        }

        .clock__weather__icon {
            color: $tsWhiteText;
        }

        .content-container__alert p {
            color: $tsWhiteText;
        }

        .visual-icon-stroke,
        .mode-icon,
        .icon {
            svg * {
                fill: none !important;
                stroke: #f2f8f8;
            }
        }

        .visual-icon-fill {
            svg * {
                fill: #f2f8f8;
            }
        }

        .masstransit__row__route-color {
            flex: 0 0 0.4em;
            border: $borderThickness solid $tsLightGray;
        }

        .masstransit__row__prediction__type,
        .docked__row__docked-location__vehicle-count,
        .docked__row__docked-location__vehicle-type-name,
        .local-events__row__name__start-time,
        .building-directory__row__location-name__close-time,
        .docked__row__vehicle-count span {
            color: $tsWhiteText;
        }

        .masstransit__row__destination__direction {
            color: $tsWhiteText;
        }

        .crowding-icon {
            .filled {
                circle,
                path {
                    fill: $tsWhiteText;
                }
            }

            .blank {
                circle,
                path {
                    fill: $tsGrayText;
                }
            }
        }
    }
}
