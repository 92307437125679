.community-message {
    position: relative;
    overflow: hidden;

    .debug-overlay {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        background: #fff;
        opacity: 0.9;
        height: 100%;
        width: 100%;
        border: 2px dashed #222;
        box-sizing: border-box;
        font-size: 0.8em;
    }

    .slide-wrap {
        position: relative;
        width: 99%; // The Carousel plugin is affecting the column width, so attempt to contain it
        margin: auto;
    }

    .carousel-root {
        // position: absolute;
        // left: 0;
        // top: 0;
        // height: 100% !important;
        // width: 100%;

        // // height: 15em;
        // z-index: 2;
    }

    .carousel .control-dots {
        position: absolute;
        bottom: 0;
        z-index: 100;
        width: 100%;
    }

    .carousel,
    .slider-wrapper {
        height: 100%;
        padding-bottom: 0.75em; // Lets control dots show below the slides
    }

    .carousel .slider {
        height: 100%;
    }

    .carousel .slide {
        height: 100%;
    }

    // These are the inner slide divs which self-assign width/height in JS
    // By reading the parent width
    &__row {
        position: relative;
        height: inherit;
        width: 100%;

        &__title {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            padding: 5px 10px;
            width: 101%;
            flex: 0 1 10%;
            text-align: center;

            @include fontSize(20px);
        }

        &__message {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
            padding: 1em 1em 2.5em 1em;
            width: 100%;
            text-align: center;
            color: $tsBlueDark;

            @include fontSize(18px);

            font-weight: 400;
            background: linear-gradient(to bottom, rgba(247, 247, 249, 0.8) 0%, #f7f7f9 100%);
        }

        img {
            object-position: center;
            height: 100%;
        }

        &.warning {
            .community-message__row__title {
                background-color: #cb4400;
                color: white;
            }
        }

        &.emergency {
            .community-message__row__title {
                background-color: #d50023;
                color: white;
            }
        }

        &.notice {
            .community-message__row__title {
                background-color: #2c68ed;
                color: white;
            }
        }

        &.celebration {
            .community-message__row__title {
                background-color: green;
                color: white;
            }
        }
    }
}
