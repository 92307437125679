.numina {
    padding: 1em 0.75em;

    .header {
        font-size: 5rem;
    }

    .stats {
        font-size: 3.2rem;

        .main {
            display: flex;
            align-items: center;

            .main-total {
                font-weight: bold;
                font-size: 1.5em;
                line-height: 1.5em;
                margin-right: 0.25em;
            }
        }

        .arrow-green {
            color: green;
        }
    }
}
