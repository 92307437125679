.AppStatus {
    z-index: 200;
    position: absolute;
    bottom: 1em;
    right: 0;
    width: 25vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0.5em;
    &.thin-sidebar {
        width: 16vw;
    }

    color: #fff;
    &.style--light {
        color: $tsBlack;
    }
    &.style--dark,
    &.style--black {
        color: #fff;
    }
}
