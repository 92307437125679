$objektivPath: "../fonts/Objektiv";

// @font-face {
//     font-family: "objektiv_mk1regular";
//     src: url("#{$objektivPath}/objektivmk1_rg-webfont.eot");
//     src: url("#{$objektivPath}/objektivmk1_rg-webfont.eot?#iefix") format("embedded-opentype"),
//         url("#{$objektivPath}/objektivmk1_rg-webfont.woff2") format("woff2"),
//         url("#{$objektivPath}/objektivmk1_rg-webfont.woff") format("woff"),
//         url("#{$objektivPath}/objektivmk1_rg-webfont.ttf") format("truetype");
//     font-weight: normal;
//     font-style: normal;
//     font-display: block;
// }

@font-face {
    font-family: "objektiv_mk1medium";
    src: url("#{$objektivPath}/objektivmk1_md-webfont.eot");
    src: url("#{$objektivPath}/objektivmk1_md-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$objektivPath}/objektivmk1_md-webfont.woff2") format("woff2"),
        url("#{$objektivPath}/objektivmk1_md-webfont.woff") format("woff"),
        url("#{$objektivPath}/objektivmk1_md-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "objektiv_mk1bold";
    src: url("#{$objektivPath}/objektivmk1_bd-webfont.eot");
    src: url("#{$objektivPath}/objektivmk1_bd-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$objektivPath}/objektivmk1_bd-webfont.woff2") format("woff2"),
        url("#{$objektivPath}/objektivmk1_bd-webfont.woff") format("woff"),
        url("#{$objektivPath}/objektivmk1_bd-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "objektiv_mk1_xbold";
    src: url("#{$objektivPath}/objektiv_mk1_xbold-webfont.eot");
    src: url("#{$objektivPath}/objektiv_mk1_xbold-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$objektivPath}/objektiv_mk1_xbold-webfont.woff2") format("woff2"),
        url("#{$objektivPath}/objektiv_mk1_xbold-webfont.woff") format("woff"),
        url("#{$objektivPath}/objektiv_mk1_xbold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

$proximaNovaPath: "../fonts/ProximaNova";

@font-face {
    font-family: "proxima_novabold";
    src: url("#{$proximaNovaPath}/proximanova-bold-webfont.eot");
    src: url("#{$proximaNovaPath}/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$proximaNovaPath}/proximanova-bold-webfont.woff2") format("woff2"),
        url("#{$proximaNovaPath}/proximanova-bold-webfont.woff") format("woff"),
        url("#{$proximaNovaPath}/proximanova-bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "proxima_novamedium";
    src: url("#{$proximaNovaPath}/proximanova-medium-webfont.eot");
    src: url("#{$proximaNovaPath}/proximanova-medium-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$proximaNovaPath}/proximanova-medium-webfont.woff2") format("woff2"),
        url("#{$proximaNovaPath}/proximanova-medium-webfont.woff") format("woff"),
        url("#{$proximaNovaPath}/proximanova-medium-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
