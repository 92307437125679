@import "./styles/mixins";
@import "./styles/variables";

@import "./styles/reset";

// Most universal fonts used across themes
@import "./styles/fonts";

// Themes themselves may have different fonts and presets
@import "./styles/themes/ThemeStyle";

@import "./styles/themes/style-light";
@import "./styles/themes/style-dark";
@import "./styles/themes/style-black";

@import "./styles/themes/theme-futura";
@import "./styles/themes/theme-futura-light";
@import "./styles/themes/theme-futura-dark";
@import "./styles/themes/theme-futura-black";

@import "./styles/themes/theme-brampton";

@import "./styles/themes/theme-actionfigure";
@import "./styles/themes/theme-actionfigure-light";
@import "./styles/themes/theme-actionfigure-dark";
@import "./styles/themes/theme-actionfigure-black";

@import "./styles/themes/theme-orangebarrel";
@import "./styles/themes/theme-orangebarrel-black";

// Theme shared
@import "./styles/App";
@import "./styles/AppStatus";
@import "./styles/BlockHeader";
@import "./styles/ContentBlock";
@import "./styles/Column";
@import "./styles/Sidebar";
@import "./styles/Crowding";
@import "./styles/RealTimeIcon";
@import "./styles/Prediction";

// Components
@import "./styles/FooterWidget";

// Blocks
@import "./blocks/Dockless/Dockless";

@import "./blocks/Greeting/Greeting";

@import "./blocks/MassTransit/MassTransit";
@import "./blocks/MassTransit/MassTransitHeader";

@import "./blocks/Messaging/Messaging";
@import "./blocks/Docked/Docked";
@import "./blocks/Floating/Floating";
@import "./blocks/Traffic/Traffic";
@import "./blocks/Traffic/TrafficV2";
@import "./blocks/BuildingDirectory/BuildingDirectory";
@import "./blocks/LocalEvents/LocalEvents";

@import "./blocks/Logo/Logo";
@import "./blocks/Clock/Clock";
@import "./blocks/Weather/Weather";
@import "./blocks/Flight/Flight";

@import "./blocks/Numina/Numina";

@import "./blocks/Parking/Parking";

@import "./blocks/TFLAlerts/TFLAlerts";

// Overrides

@import "./styles/ie";
