.dockless {
	&__header {
		display: flex;
		flex-wrap: nowrap;
		align-items: baseline;
		&__title {
			flex: 0 1 100%;
			margin-right: 0.2em;
			display: block;
			font-family: $secondaryFontBold;
			font-weight: 800;
		}
		&__min-header {
			font-family: $secondaryFont;
			flex: 0 1 100%;
			font-size: 0.7em;
			text-align: right;
		}
	}
	&__row {
		&__walk-time {
			text-align: right;
		}
	}
}