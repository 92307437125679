.Crowding {
    display: flex;
    align-items: center;
    height: inherit;
    margin-right: 0.5em;
    .crowding-icon {
        width: 0.5em; // Icon seems to only resize with width not height
        height: 1.1em;
        svg {
            * {
                fill: #373739;
            }
        }
        &.empty svg * {
            stroke: #373739;
            stroke-width: 4;
            fill: none;
        }
    }
}
