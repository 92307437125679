body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

p {
    margin: 1rem 0;
}

*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    // text-rendering: optimizeLegibility;

    font-smooth: always;
    font-smoothing: subpixel-antialiased;
    -moz-font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: subpixel-antialiased;
}

body {
    margin: 0;
    color: $tsBlack;
    font-size: 95%;
    font-family: $primaryFont;

    //    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

sup {
    font-size: inherit;
}

:focus {
    outline: none;
}

img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
}

a {
    text-decoration: none;
}

svg {
    width: 100%;
}

.block-logos {
    svg, g, path, ellipse, line, circle {
        // vector-effect: non-scaling-stroke; // Consistent line widths

    }
}