.greeting {
    &__block {
        font-family: $secondaryFont;
        border-radius: 1em;
        padding: 0.5em;
        color: #fff;
        background: rgb(15, 29, 88);
        background: -moz-linear-gradient(0deg, rgba(15, 29, 88, 1) 0%, rgba(28, 50, 137, 1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(15, 29, 88, 1) 0%, rgba(28, 50, 137, 1) 100%);
        background: linear-gradient(0deg, rgba(15, 29, 88, 1) 0%, rgba(28, 50, 137, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f1d58",endColorstr="#1c3289",GradientType=1);
        .regular-code-block {
            position: relative;
            padding: 0;

            .header {
                text-align: center;
                display: flex;
                justify-content: center;
                @media (max-width: 1100px) {
                    display: block;
                }
                .app-logo {
                    flex: 0 0 50%;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 80%;
                        height: auto; // Cenique needs this to have definitive height
                    }
                }
                .text {
                    font-size: 0.6em;
                    padding-right: 0.5em;
                    b {
                        color: #5cdfff;
                    }
                }
            }

            .content {
                margin-top: 0.5em;
                text-align: center;
                .text {
                    font-weight: 200;
                    font-size: 0.8em;
                }

                .app-code {
                    font-size: 1.5em;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;
                    text-transform: uppercase;
                    div {
                        text-transform: uppercase;
                        flex: 0 1 1.5em;
                    }
                }
            }
        }
        .qr-code-block {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .qr-code {
                flex: 0 0 6em;
                padding-right: 0.5em;
                margin: 0 0.5em 0 0.5em;
                svg {
                    width: 100%;
                    height: auto;
                    background: #fff;
                    border-radius: 0.75em;

                    #r0 {
                        fill: #000;
                    }
                }
            }
            .text {
                text-align: center;
                line-height: 1.5em;
                padding: 0.5em 0 0.5em;
                b {
                    color: #5cdfff;
                }
                .app-logo {
                    width: 80%;
                    margin: auto;
                    padding: 0.5em;
                }
                flex: 0 1 auto;
                font-size: 0.7em;
            }
        }
    }
}

.thin-sidebar {
    .greeting {
        &__block {
            .qr-code-block {
                flex-direction: column;
                .qr-code {
                    padding: 0.5em;
                }
                .app-logo {
                    width: 100%;
                }
            }
            .regular-code-block {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                .text {
                    padding: 0.5em;
                }
                .app-code {
                    align-items: space-between;
                    justify-content: space-between;
                    > div {
                        margin: 0;
                    }
                }
            }
        }
    }
}
