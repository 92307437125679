.screen-wrapper {
    &.thin-sidebar {
        .screen-content__main {
            flex: 0 0 85%;
            width: 85%; // Important to have children respect this width
        }

        // out of 24
        .screen-content__sidebar {
            flex: 0 0 15%;
            width: 15%;

            .logo {
                flex-wrap: wrap;

                &__walk-score,
                &__mobility-score {
                    width: 45%;
                }
            }
        }

        .greeting {
            font-size: 0.8em;
        }

        .logo__walk-score,
        .logo__mobility-score {
            // height: 2em;
            @include fontSize(15px);
        }
    }

    &.wide-sidebar {
        .screen-content__main {
            flex: 0 1 75%;
            width: 75%;
        }

        .screen-content__sidebar {
            flex: 1 0 25%;
            width: 25%;
        }
    }

    &.no-sidebar {
        .screen-content__sidebar {
            display: none;
        }
    }

    // two column weather
    .screen-content__main {
    }
}
