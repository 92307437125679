.logo {
    // In event the image logo gets scaled down due to max-height, center it
    text-align: center;
    color: $tsBlack;

    img.customer-logo {
        max-height: 8em;
        width: 100%; // 100% of parent hopefully
        margin-left: auto;
        margin-right: auto;
    }

    .badges {
        margin-top: 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        &.stacked {
            display: grid;

            div:not(:first-child) {
                margin-top: 0.5em;
            }
        }
    }

    .walk-score, .mobility-score, .tas-bronze, .tas-gold, .tas-platinum, .tas-silver, .transit-score, .leed-basic, .leed-silver, .leed-gold, .leed-platinum, .energy-star, .leed-ca-basic, .leed-ca-silver, .leed-ca-gold, .leed-ca-platinum, .boma-360, .boma-basic, .boma-bronze, .boma-gold, .boma-silver, .boma-platinum, .breeam,
    .walk-score,
    .mobility-score {
        flex: 0 0 50%;
        position: relative;
        width: 6em;
        height: 5em;
        max-height: 150px;

        .badge {
            width: 100%;
            height: 100%;

            // ReactSVG annoying nests this way
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                svg {
                    width: auto;
                    height: 100%;
                }
            }
        }

        // This number should NOT scale with zoom
        .number {
            font-weight: bold;
            font-size: 1.4em;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            margin: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
