.FooterWidget {
    // Footer is fixed because the adjacent screen-inner div is difficult to adjust in flex
    position: fixed;
    bottom: 0;
    left: 0;
    height: 8vh;
    width: 100%;
    display: flex;
    background: #3a3537;
    color: $tsWhite;

    font-size: 0.8em;

    .NewsTicker {
        flex: 1 0 40%;
        width: 40%;
        padding-left: 2em;

        .slide-wrap {
            width: 100%;
        }
        p {
            margin: 0.5em 0 0.5em 0;
        }
        .title {
            font-size: 1.2em;
            margin: 0.5em 0 0 0;
        }
        .description {
            color: #ccc;
            margin: 0;
            padding: 0;
            height: 1.5em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .carousel {
            .slider-wrapper {
                padding-bottom: 1.5em;
            }
            .control-dots {
                text-align: left;
            }
            .dot {
                background: #ccc;
            }
        }
    }
    .StockTicker {
        font-size: 1.2em;
        flex: 1 0 35%;
        padding-left: 1em;
        padding-top: 0.5em;
        display: flex;
        justify-content: space-evenly;
        .stock {
            p {
                display: inline-block;
                padding: 0;
                margin: 0;
                &.last {
                    font-size: 0.8em;
                }
            }
        }
    }
    .ActionFigureLogo {
        text-align: center;
        color: #222;
        flex: 0 0 15em;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 1.5em;
            width: auto;
        }

        .qr-code svg {
            margin-left: 0.5em;
            height: 3em;
            width: 3em;
            > rect {
                fill: none;
            }
            * {
                fill: #888;
            }
        }

        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #eee;
            -webkit-transform-origin: 100% 0;
            -ms-transform-origin: 100% 0;
            transform-origin: 100% 0;
            -webkit-transform: skew(-30deg);
            -ms-transform: skew(-30deg);
            transform: skew(-30deg);
            z-index: -1;
        }
    }
}
