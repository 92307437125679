// @TODO Brampton does not use ThemeStyle, componentry different
.theme--brampton.screen-wrapper {
    color: #000;
    background: $tsWhite;

    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    padding: 1em 1em 1.5em 1em; // While all values are set to 100%, this sets the buffer whitespace

    .screen-wrapper__inner {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
        padding-top: 1em;

        border-bottom: 1px solid $tsBlue; // Bottom was removed on default theme
    }

    // Weather icon uses a font to display images
    *:not(.wi) {
        border-color: $tsBlue !important;
        border-width: 1px !important;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .mode-icon {
        * {
            stroke: $tsBlue !important;
        }
    }

    .screen-content__main {
        position: relative;
        flex: 0 1 100%;
        width: 100%; // important to continue inheriting the parent width here
        height: 100%;
        max-height: 100%;
        height: 100vh;
        display: flex;
    }

    .screen-content__column {
        // border-right: 1px solid $tsBlue;
    }

    // .screen-content__main:before {
    //     border-color: $tsBlue !important;
    //     border-width: 1px !important;
    // }

    .masstransit__header {
        font-size: 0.8em;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        margin: 0.2em 0 0.2em 0;
        padding-bottom: 0.5em;
        border-bottom: 1px solid $tsLightGray;
    }

    .content-block {
        position: relative;
        padding: 1em 0.5em 1.5em 1em;
        box-sizing: border-box; // Important to set so that children understand width is actually 100% without padding
        border-top: 1px solid $tsBlue;

        /*
        Blocks that are "grouped" do not have line separators or logos
        */
        @for $i from 1 through 5 {
            &--group-#{$i} {
                border: none;
                padding-bottom: 0.5em;

                .block-header {
                    display: none;
                }
                &.first-child {
                    .block-header {
                        display: flex;
                    }
                    border-top: 1px solid $tsBlue;
                }
                &.last-child {
                    padding-bottom: 1.5em;
                }
            }
        }

        * {
            border-color: $tsLightGray !important;
        }

        &.clock {
            padding: 0.5em 0.5em 1em 0.5em;
        }

        &.static-text {
            padding-top: 1em;
            padding-bottom: 0;
        }

        .content-container__header::after {
            display: none;
        }

        .error-message {
            font-size: 0.7em;
            padding: 0.5em 0 1em 0;
        }

        .clock__weather,
        .clock__weather__icon {
            color: $tsBlue !important;
            vertical-align: middle;
        }

        .clock__weather__temperature {
            font-size: 1.2em;
            vertical-align: middle;
        }

        .clock__row__clock-date {
            font-size: 1em;
            margin-left: 0.5em;
        }

        .block-logos {
            display: flex;
            align-items: center;
            position: absolute;
            top: -1.2em;
            left: -0.5em;
            z-index: 10;
            background: $tsWhite;
            padding-left: 0.5em;
            height: 2em;
        }

        &.masstransit .block-logos {
            // display: flex;
            // align-items: center;
            .mode-icon {
                margin: 0;
                padding: 0;
                height: 1.2em;
                vertical-align: middle;
                svg {
                    width: auto;
                    height: 1.2em;
                }
            }
            .agency-logo {
                vertical-align: middle;
                margin: 0 0 0 0.5em;
                padding: 0;
                height: 2.5em;

                svg {
                    width: auto;
                    height: 2.5em;
                    max-height: unset;
                }
            }
        }
    }

    .masstransit__row {
        padding-top: 0.2em; // Give slight visual spacing due to SCHED.
        padding-bottom: 0.2em; // Give slight visual spacing due to SCHED.
        position: relative;
        border-top: 1px solid #aaa;

        &:first-child {
            border: none;
        }

        .masstransit__row__icon {
            flex: 0 0 auto; // 0 0 auto means text content width is always respected
            padding: 0 0.5em 0 0.5em;
            border-radius: 3em;
            min-width: 2em;
            min-height: 2em;
            text-align: center;
            font-weight: 500;
            line-height: 2em;
        }

        .masstransit__row__destination {
            font-weight: 500;
            padding: 0.5em 0 0.5em 0;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

            .masstransit__row__destination__name {
                margin-left: 0.5em;
                hyphens: none;
            }
        }

        .masstransit__row__prediction {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 500;
            flex: 1 0 50%;
            flex-direction: row;
            display: flex;

            // Align elements to baseline when "scheduled"
            align-items: flex-end;

            .masstransit__row__prediction__vehicle {
                flex: 0 1 33.3%;
                text-align: right;
                position: relative;

                .content-block__row__prediction-ampm {
                    margin-left: 0.2em;
                    font-size: 0.8em;
                }

                .content-block__row__prediction-number {
                    // clear: both;
                    font-size: 1em;
                    font-weight: 500;
                }

                .masstransit__row__prediction__type {
                    // display: inline-block;
                    color: $tsBlack;
                    font-size: 0.6em;
                    position: absolute;
                    top: -1.2em;
                    right: 0;
                }
            }
        }
    }

    .content-container__row__prediction-number:first-child {
        margin: 0;
    }

    .content-container--group-2 + .content-container--group-2:not(.community-message) {
        padding-bottom: 1em;
    }
}
