.theme-style-wrapper.theme--black {
    &.theme--futura {
        color: white;
        background: #000;

        .screen-content {
            div {
                border-color: $tsWhite;
            }
            div::after {
                border-color: $tsWhite;
                background: #000;
            }
        }

        .screen-content__main {
            background: #000;
            &::after {
                border-color: #fff;
                background: #000;
            }
        }

        .border-element .piece {
            background: #fff;
        }

        .screen-logo {
            background: #000;

            svg {
                polyline {
                    stroke: $tsLightModeBorder;
                }

                path {
                    fill: $tsWhite;
                }
            }
        }

        .screen-footer {
            color: $tsWhite;
        }
        .masstransit__row {
            background: #000;
            border-color: $tsGray !important;
        }

        .block-logos {
            background: #000;
        }

        .masstransit__header__title {
            color: #eee;
        }

        div,
        div:before {
            border-color: #f2f8f8;
        }

        h2.content-container__header,
        div.content-container__row {
            border-color: #888 !important;
        }

        .slide-wrap .control-dots .dot {
            background: #aaa;
        }

        .clock__weather__icon {
            color: $tsWhiteText;
        }

        .visual-icon-stroke,
        .mode-icon,
        .icon {
            svg * {
                stroke: #f2f8f8 !important;
                fill: none !important;
            }
        }

        .visual-icon-fill {
            svg * {
                fill: #f2f8f8 !important;
            }
        }

        .masstransit__row__prediction__type,
        .docked__row__docked-location__vehicle-type-name,
        .local-events__row__name__start-time,
        .building-directory__row__location__distance,
        .building-directory__row__location-name__close-time,
        .docked__row__vehicle-count span {
            color: #eee;
        }

        .greeting__block.citymo-code {
            .header {
                color: #000;
            }

            .app-code {
                background: $tsWhite;
                color: #000;
            }

            .qr-code-block .qr-code #r0 {
                fill: #fff;
            }
        }

        .crowding-icon {
            .filled {
                circle,
                path {
                    fill: $tsWhiteText;
                }
            }

            .blank {
                circle,
                path {
                    fill: $tsGrayText;
                }
            }
        }
    }
}
