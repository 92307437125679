.building-directory {
    &__header {
        text-align: center;
        width: 80% !important;
        margin: 0 auto 0.5em auto !important;
    }

    &__row {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.2em;

        &__icon {
            flex: 0 0 1.5em;
            margin-right: 0.5em;
            height: 1.5em;
            width: 1.5em;

            svg {
                width: 100%;
                height: 100%;
                // Override svg coloring
                * {
                    fill: $tsDarkGreenBorder;
                }
            }
        }

        &__location-name {
            flex: 0 1 75%;
            line-height: 1.2em;
            font-size: 0.8em;

            span {
                font-family: $secondaryFont;
                display: block;
            }

            &__close-time {
                padding-left: 0.5em;
            }
        }

        &__location {
            flex: 0 1 50%;
            text-align: right;
            font-size: 0.8em;

            span {
                display: block;
            }

            &__distance {
            }
        }

        .building-directory__row__location-name__close-time {
            font-size: 0.8em;
        }
    }
}