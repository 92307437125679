.ContentBlock.parking {
    .content-container__header {
        display: flex;
        align-items: center;
        .title {
            flex: 0 1 75%;
            font-size: inherit;
        }
        .prediction {
            flex: 0 0 40%;
            font-size: 0.7em;
            text-align: right;
            font-family: $secondaryFont;
        }
    }

    .rows {
        .content-container__row {
            display: flex;
            .location {
                flex: 1 0 50%;
                font-size: 0.8em;
            }
            .prediction {
                flex: 1 1 50%;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .color {
                    width: 0.5em;
                    height: 0.5em;
                    border-radius: 1em;
                    margin: 0.5em;
                    background: #eee;
                }
            }
        }
    }
}
