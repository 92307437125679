.Prediction {
    flex: 0 0 auto; // 0 0 auto means text content width is always respected and wont break
    min-width: 5em; // Force a min width to provide anchor for crowding icons on left
    position: relative;
    font-family: $secondaryFont;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    // When both values become clock time, they stack
    &.Prediction--ClockTime {
        max-width: 5em;
        flex-wrap: wrap;
        padding-bottom: 0.2em;
        .separator {
            display: none;
        }
    }

    .WaitTimeText {
        flex: 1 1 auto;
        text-align: right;
    }

    .TimePrediction {
        position: relative;
        flex: 1 0 auto;
        text-align: right;
        min-width: 2.3em; // ensures vertical line up from right to left for two-digit numbers

        .separator {
            float: left;
            padding-left: 0.2em;
        }

        .MinutesTime {
        }

        .ClockTime {
            padding-left: 0.4em; // sometimes it pushes left beyond the min-width
        }
    }
}
