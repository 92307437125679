.floating {
    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 1.6em;
        &__vehicle-logo {
            flex: 0 1 15%;
            margin-right: 0.5em;
            display: flex;
            align-items: center;
            svg {
                width: auto;
                height: 1em;
            }
        }
        &__vehicle-type {
            flex: 0 1 70%;
            font-size: 0.8em;
        }
        &__surge-multiplier {
            flex: 0 1 20%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__inner {
                border: 1px solid;
                border-radius: 1em;
                padding: 0 0.5em 0 0.5em;
                font-size: 0.8em;
                line-height: 1.2em; // Creates vertical alignment
            }
        }
        &__prediction {
            flex: 0 0 25%; // Preserves prediction width of 5 chars max (10-15 min away)
            text-align: right;
            &__time {
            }
        }
    }
}
