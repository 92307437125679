.ContentBlock {
    /*
        Blocks that are "grouped" do not have line separators or logos
    */
    @for $i from 1 through 5 {
        &.group-#{$i} {
            .content-container {
                padding-bottom: 1em;
            }
            .block-header {
                display: none;
            }
            &.first-child {
                .block-header {
                    display: flex;
                }
            }
            &.last-child {
                .content-container {
                    padding-bottom: 1.5em;
                }
            }
        }
    }

    .content-container {
        position: relative;
        padding: 0em 1em 1.5em 1em;
        width: 100%;
        box-sizing: border-box; // Important to set so that children understand width is actually 100% without padding

        &__header {
            position: relative;
            width: 100%;
            margin: 0 0 0.2em 0;
            padding-bottom: 0.2em;
            font-family: $secondaryFontBold;
            font-weight: 800;
            border-bottom: 1px solid $tsGray;
            font-size: 0.8em;
        }
        &__row {
            align-items: center;
            border-bottom: 1px solid $tsLightGray;
            &:last-child {
                border: none;
            }
            &__prediction-number {
                font-weight: 400;
                font-family: $secondaryFont;
                text-align: right;
                min-width: 1.2em; // Reserves space for numerals to align right vertically
                &:last-child {
                    margin-left: 0.25em;
                }
            }
        }
        &__alert {
            position: relative;
            padding-bottom: 0.4em;
            font-size: 0.6em;
            .alert {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0em;
                    width: 1.2em;
                    height: 1.2em;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.795 17.115C19.67 18.83 18.795 20.23 16.87 20.23H3.85C1.925 20.23 1.05 18.83 1.925 17.115L8.785 3.465C9.66 1.75 11.06 1.75 11.935 3.465L18.795 17.115Z' stroke='%23FCA416' stroke-width='2px' stroke-miterlimit='10'/%3E%3Cpath d='M10.36 8.05V13.825' stroke='%23FCA416' stroke-width='2px' stroke-miterlimit='10'/%3E%3Cpath d='M10.36 16.66C10.8432 16.66 11.235 16.2682 11.235 15.785C11.235 15.3018 10.8432 14.91 10.36 14.91C9.87674 14.91 9.48499 15.3018 9.48499 15.785C9.48499 16.2682 9.87674 16.66 10.36 16.66Z' stroke='%23FCA416' stroke-width='2px' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
                }
            }
            p {
                position: relative;
                padding-left: 2em;
                margin-top: 0;
                margin-bottom: 0;
                display: inline;
                span {
                    color: #555;
                }
            }
        }
        &--error {
            padding: 0.6em 0.2em 0;
            margin-bottom: -0.2em; // @include fontSize(14px);
        }
        .error-message {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 1em;
            width: 100%;
            text-align: center;
            @include fontSize(13px);

            .agency-logo svg {
                padding-left: 0.5em;
                height: 1.5em;
                width: auto;
            }
        }
        &.weather,
        &.logo {
            border-top: 0;
            &:nth-of-type(1):not {
                margin-top: -4em;
            }
            .logo {
                display: none;
            }
        }
        &.logo {
            margin-bottom: 0.5em;
        }
    }
}
