// @TODO All this needs to be nested in App or spun off into their own CSS files

.App {
    position: relative;
    width: 100%;
    height: 100vh; // Important in event of theme failure
    transition: opacity 200ms linear;
    font-family: $primaryFont;

    &.loading,
    &.billingOff,
    &.error,
    &.deployment {
        .screen-content__main {
            .screen-content__column {
                // This is because border-top has been replaced with div shims
                // to achieve transparency between lines and icons
                border-top: 1px solid $tsLightModeBorder;
            }
        }
    }

    // In recovery mode, the app is repeating the update call, so hide old data
    &.recovery {
        .ContentBlock,
        .content-block {
            transition: opacity 500ms ease-out;
            opacity: 0;
        }
    }
}

.hide-img {
    display: none !important;
}

.screen-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh; // vh will include padding in its calculation
    padding: 0 0.5em 1.5em 0.5em;
    overflow: hidden;

    &__inner {
        position: relative;
        height: 100%;
        width: 100%;
    }
}

.screen-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;

    &__main {
        position: relative;
        flex: 0 1 100%;
        width: 100%; // important to continue inheriting the parent width here
        height: 100%;
        max-height: 100%;
        height: 100vh;
        display: flex;

        &__inner {
            display: flex;
            padding-top: 1em;
            overflow: hidden;
            width: 100%; // important
        }

        .screen-content__column {
            border-left: $borderThickness solid $tsLightModeBorder;
            &:last-child {
                border-right: $borderThickness solid $tsLightModeBorder;
            }
        }

        &.two-column .screen-content__column {
            flex: 0 0 50%;
            width: 50%; // This is required to force interior elements to respect the width set by flexbox
        }

        &.three-column .screen-content__column {
            flex: 0 0 calc(100% / 3);
            width: calc(100% / 3); // This is required to force interior elements to respect the width set by flexbox
        }
    }

    &__footer {
        display: flex;
        position: absolute;
        bottom: -1.2em; // Guestimate position to line up outer border
        left: 0;
        width: 100%;
        z-index: 10;
        align-items: center;

        .border-element .piece {
            width: 100%;
            background: $tsLightModeBorder;
            height: $borderThickness;
        }
        .border-element-left {
            flex: 0 1 90%;
        }
        .border-element-right {
            flex: 1 0 3em;
        }

        .screen-logo {
            flex: 0 0 2.5em;
            width: 2.5em;
            height: 2.5em;
            padding: 0 0.5em 0 0.5em;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__sidebar {
        flex: 1 0 25%; // 1 0 25% enforce sidebars width relative to main 100%
        box-sizing: border-box;
        padding-left: 0.5em;
        width: 25%; // This is required to force interior elements to respect the width set by flexbox

        .screen-content__column {
            padding: 1em 0 0.5em 0;
            overflow: hidden;
        }

        .ContentBlock {
            .content-container {
                padding: 0;
                margin-bottom: 1em;

                & + .content-container {
                    margin-top: 0 !important;
                    border-top: 0;
                }

                .block-header {
                    display: none;
                }
            }
            .clock,
            .weather,
            .greeting {
                margin-top: -0.5em;
                margin-bottom: 0.5em;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
            }
        }

        .block-header {
            display: none; // All themes do not show block header in sidebar
        }
    }

    // Main and Sidebar wrap this class be careful
    &__column {
        position: relative;
        width: 100%; // Width is edited in the wrapper classes
        height: 100%;

        flex-grow: 1;
    }
}

.error-message {
    text-align: center;
}

.slide-wrap {
    overflow: hidden;

    // Super important here, the carousel responsively matches the parent width
    width: inherit;

    .carousel {
        position: relative;
        width: 100%;
    }

    .slide {
        width: 100%;
        min-width: 100%;
        margin: 0;
        position: relative;
        overflow: hidden;
    }

    .control-dots {
        list-style: none;
        text-align: center;
        padding: 0;

        .dot {
            display: inline-block;
            background-color: $tsLightModeBorder;
            width: 0.5em;

            //10px
            height: 0.5em;

            //10px
            border-radius: 50%;
            opacity: 0.45;
            margin: 0 1em;

            &.is-selected,
            &.selected {
                opacity: 1;
            }

            &:only-of-type {
                visibility: hidden;
            }
        }
    }
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    display: none;
}

.carousel-root {
    outline: none;
}

.carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
}

.carousel .carousel {
    position: relative;
}

.carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in;
    padding-bottom: 1em;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
    display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column;
}

.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}

.carousel .slider.animated {
    transition: all 0.35s ease-in-out;
}

.carousel .slide {
    // text-align: center;
    //   background: #000;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}

.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}

.carousel .slide .legend {
    display: none;
}

.carousel .control-dots {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
}

.carousel .carousel-status {
    display: none;
}

.carousel:hover .slide .legend {
    opacity: 1;
}

.footer-support {
    margin-top: 0.5em;
    position: relative;
    padding-left: 1em;

    @include fontSize(10px);

    color: $tsLightModeFooterText;
    z-index: 3;
    height: $screenBorderPadding;

    a {
        color: inherit;
    }
}

@media only screen and (max-width: 600px) {
    .footer-support {
        padding-left: 0.5em;
    }
}
