// https://erikflowers.github.io/weather-icons/
@import "weather-icons/weather-icons";

.Weather {
    font-family: $secondaryFont;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.6em;
    flex-wrap: wrap;

    .prediction {
        flex: 1 0 auto;
        min-width: 100px;
        margin-bottom: 1em;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;

        .details {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .time {
            margin-right: 0.5em;
        }

        .label {
            // text-transform: uppercase;
            margin-top: 0.2em;
            padding: 0 0.1em;
        }

        .weather-icon img {
            width: 2em;
            height: 2em;
            text-align: center;
            display: inline-block;
        }
    }
}

.thin-sidebar .Weather {
    .prediction {
        flex: 0 0 100%; // Single rows
    }

}
