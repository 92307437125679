.theme-style-wrapper.theme--actionfigure {
    .screen-content__main,
    .screen-content__column {
        border-width: 1px !important;
    }
    .piece {
        height: 1px !important;
    }
    .block-header {
        top: -0.56em; // Adjust negative alignment
        .icons {
            flex-direction: row-reverse;
            .agency-logo,
            .mode-icon {
                padding: 0 0 0 0.5em;
            }
            .mode-icon:first-child {
                padding-right: 0.5em;
            }
        }
    }
    .screen-content__footer {
        align-items: flex-start;
        bottom: -2em;
        height: 2em;
        .screen-logo {
            flex: 0 0 10%;
            min-width: 150px;
            height: inherit;
            margin-top: -0.5em;
        }
    }
    .content-container {
        // padding: 0.5em 1em 1.5em 1em;
        .content-container__header {
        }
    }
}
