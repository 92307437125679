.masstransit__header {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 1.2em;
    &__title {
        flex: 0 0 100%;
        margin-right: 0.2em;
        display: block;
    }
    &__walk-minutes {
        flex: 0 0 50%;
        font-family: $primaryFont;
        font-weight: 200;
        font-size: 0.6em;
        .wrapper {
            white-space: nowrap;
            display: flex;
            align-items: baseline;
        }
        .icon {
            margin-right: 0.5em;
            svg {
                width: auto;
                height: 1.2em;
                * {
                    stroke-width: 2;
                }
            }
        }
        span {
            margin-right: 0.2em;
            font-size: 1.2em;
        }
    }
    &__min-header {
        font-family: $secondaryFont;
        flex: 0 0 50%;
        font-size: 0.7em;
        text-align: right;
    }
}
