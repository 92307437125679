.tfl-alerts {
    .header {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        .logo {
            flex: 0 0 1.5em;
            width: 1.5em;
            height: 1.3em;
            margin-right: 0.2em;
        }
        .title {
            flex: 1 1 auto;
            font-size: 0.8em;
            font-family: $secondaryFontBold;
        }
    }
    .row {
        display: flex;
        align-items: stretch; // Color fill the height for .route
        margin-bottom: 0.5em;
        .route {
            flex: 1 1 50%;
            font-size: 0.7em;
            font-family: $secondaryFont;
            display: flex;
            align-items: center;
            .linebox {
                flex: 1 1 5%;
                height: 2em;
            }
            .route-name {
                padding-left: 0.3em;
                padding-right: 0.3em;
            }
        }
        .effect {
            padding-left: 0.5em;
            flex: 0 1 50%;
            font-size: 0.8em;
            display: flex;
            align-items: center;
        }
    }
}
