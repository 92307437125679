// This is the parent class component of most theming files
// These toggle classes can be altered per theme by adding a &.theme--{name} class

.theme-style-wrapper {
    
    &.has-background-image {
        background-size: cover;
        .content-container__row {
            background: none !important;
        }
        .block-logos::after {
            display: none;
        }
        .screen-content__main {
            background: none !important;
        }
        .screen-content__main:after {
            background: none !important;
        }
    }

    &.has-footer-widget {
        display: flex;
        flex-direction: column;
        padding-bottom: 1em;
        height: 92vh; // Footer widget is 8vh
        .screen-logo {
            display: none;
        }
    }

    &.hide-outer-border {
        .screen-content__main:after {
            display: none;
        }
        .screen-logo {
            display: none;
        }
        .piece {
            display: none;
        }
        .content-container,
        .screen-content__column,
        .screen-content__main {
            border: none !important;
        }
        .block-logos {
            top: -0.2em;
        }
    }

    &.hide-row-lines {
        .masstransit__row,
        .content-container__row {
            border: none !important;
        }
    }
}
