.flight {
    &__header {
        &:after {
            display: none;
        }
    }

    &__row {
        list-style-type: none;
        width: 100%;

        @include fontSize(16px);

        display: flex;
        align-items: center;
        min-height: 3.5em;

        &__destination {
            flex: 0 1 25%;
            font-family: $secondaryFontBold;

            // @include fontSize(18px);
        }

        &__flight-info {
            flex: 1 1 35%;
            display: flex;
            align-items: center;
            font-family: $secondaryFont;

            &__logo {
                flex: 0 0 2em;
                width: 2em;
                height: 2em;
                margin-right: 0.5em;
            }

            &__call {
                flex: 0 1 100%;
            }

            font-size: 0.8em;

            &__name {
                line-height: 1.2em;
                display: block;
            }
        }

        &__terminal-gate-info {
            flex: 0 1 15%;
            display: flex;

            .terminal-info, .gate-info {
                display: block;
                line-height: 1.2em;
            }
        }

        &__departure {
            flex: 1 0 15%;
            text-align: right;

            .original-time {
                white-space: nowrap;
                color: $tsGray;
                margin-bottom: -0.2em;
                text-decoration: line-through;
            }

            .actual-time {
                white-space: nowrap;
                font-size: 1.2em;
            }
        }

        &__status {
            flex: 1 0 17%;
            position: relative;
            text-align: right;
            padding-left: 0.5em; 

            .delayed {
                color: #e1552a;
            }
        }
    }
}
