.local-events {
    &__header {
        text-align: center;
        margin: auto;
        margin-bottom: 1em;
        width: 90%;
    }

    &__row {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.2em 0.5em 0.2em 0.5em;

        &__icon {
            flex: 0 1 1.2em;
            margin-right: 0.5em;
        }

        &__name {
            flex: 1 1 60%;
            padding-right: 0.5em;
            font-family: $secondaryFont;
            font-size: 0.8em;

            span {
                display: block;
            }

            &__start-time {
                padding: 0 0 0.5em 0.5em;
                color: $tsDarkGray;
                font-size: 0.8em;
            }
        }

        .venue {
            flex: 1 1 50%;
            text-align: right;
            font-size: 0.8em;
        }
    }
    // Override the stylesheet inside SVGs
    .st0 {
        fill: $tsDarkGreenBorder;
    }
}