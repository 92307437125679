.theme-style-wrapper.theme--black.theme--orangebarrel {
    .screen-content__column {
        border-color: #fff;
    }
    .border-element .piece {
        background: #fff;
    }
    .screen-logo svg {
        polyline {
            stroke: #fff;
        }
        path {
            fill: #fff;
        }
    }
    .mode-icon svg * {
        stroke: #fff;
    }
    .icon svg * {
        fill: #fff;
    }
}
