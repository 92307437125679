// Responsive design presets
$sm: 320px;
$smbg: 480px;
$md: 767px;
$mdbg: 1024px;
$hg: 1200px;
$hgxl: 1400px;
$screen720p: 1300px;

// Type
$baseFontSize: 36px;
$primaryFont: "proxima_novamedium";
$primaryFontBold: "proxima_novabold";
$secondaryFont: "objektiv_mk1medium";
$secondaryFontBold: "objektiv_mk1_xbold";

// Brand Colors
$tsBlue: #2196c9;
$tsBlueLight: #84cceb;
$tsBlueDark: #223b47;
$tsGreen: #7fec60;
$tsGray: #aaa;
$tsLightGray: #b7acb1;
$tsWhite: #f9faff;

// New colors
$tsAqua: #59eace;
$tsDarkGreenBorder: #18626d;
$tsDarkGreenBackground: #103d46;
$oldGreen: #134a55;
$tsBlack: #3a3537;
$cmOrange: rgb(255, 98, 29);
$tsWhiteText: #f2f8f8;
$tsGrayText: #b2b8b8;
$tsGrayBorder: #c2c8c8;
$tsDarkGray: #676768;

// Targeted

$appCodeBackground: $tsDarkGreenBackground;

$tsLightModeFooterText: #555;

$tsLightModeBorder: #18626d;
$tsLightModeRowBorder: #c2c8c8;

$tsDarkModeBorder: #07a5a4;
$tsDarkModeRowBorder: #186664;

// Initial base css design decisions, should rarely be seen due to config call

$sizing: 2.5em; // General height setter
$borderThickness: 1px;
$iconHeight: 1.5em;
$screenBorderPaddingVertical: 1em;
$screenBorderPaddingHorizontal: 0.7em;

@include media($md) {
    $screenBorderPaddingVertical: 0.92em;
    $screenBorderPaddingHorizontal: 0.92em;
}

$screenBorderPadding: $screenBorderPaddingVertical $screenBorderPaddingHorizontal;
