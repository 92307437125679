.clock {
    font-family: $secondaryFont;

    &__row {
        text-align: center;
        margin-bottom: 0.5em;

        &__clock-time {
            white-space: nowrap;
            font-weight: bold;
            padding: 0.5em;
        }

        &__clock-date {
            padding: 0.5em;
        }
    }

    .Weather {
        font-size: 1em; // Reset the font sizing

        // Weather is displayed as a single line of info
        .prediction {
            display: flex;
            align-items: center;
            justify-content: center;
            .details {
                padding-left: 0.5em;
            }
        }

        // Hide certain elements when displaying weather in clock
        .time,
        .label {
            display: none;
        }
    }
}

.thin-sidebar .clock {
    .clock__row__clock-date {
        display: block;
        font-size: 0.7em;
    }
    .Weather {
        font-size: 0.8em;
    }
    .separator {
        display: none;
    }
}
