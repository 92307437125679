.theme-style-wrapper.theme--dark {
    &.theme--actionfigure {
        color: #fff;
        background: $tsBlack;

        &:not(.has-background-color) {
            background: #000724;
            .content-container__row {
                background: #000724;
            }
        }

        .content-container__row {
            border-color: #555;
        }
        .border-element .piece {
            background: #373739;
        }

        .screen-content__main,
        .screen-content__column {
            border-color: #373739 !important;
        }
        .mode-icon svg * {
            fill: #fff;
        }
        .visual-icon-stroke svg * {
            stroke: #fff;
        }
        .visual-icon-fill svg * {
            fill: #fff;
        }

        .slide-wrap .control-dots .dot {
            background: #fff;
        }

        .crowding-icon {
            svg * {
                fill: #fff;
            }
            &.off {
                svg * {
                    fill: #888;
                }
            }
        }
    }
}
