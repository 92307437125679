.block-header {
    z-index: 2;
    height: 1.2em;
    flex-wrap: nowrap;
    position: relative;
    top: -0.57em; // Because screen outer padding is in ems, we use ems here, respects zoom scaling
    left: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &.align-right {
        .border-element-left {
            flex: 1 1 auto;
        }
        .border-element-right {
            flex: 0 0 0.75em;
        }
    }

    .border-element {
        position: relative;
    }

    .border-element-left {
        flex: 0 0 0.75em;
    }

    .border-element-right {
        flex: 1 1 auto;
        overflow: auto;
        .piece {
            right: -2px;
        }
    }

    .piece {
        width: 100%;
        background: $tsLightModeBorder;
        height: $borderThickness;
    }

    .icons {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .mode-icon,
        .agency-logo {
            flex: 0 1 auto;
            height: 1.5em;
            padding-right: 0.5em;
        }
        .mode-icon:first-of-type {
            padding-left: 0.5em;
        }
    }

    svg {
        width: auto;
        height: 1.5em;
    }
}

.screen-content__sidebar {
    .block-header {
        position: relative;
    }
}
