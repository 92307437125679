.docked {
	&__header {
		display: flex;
		flex-wrap: nowrap;
		align-items: baseline;
		&__title {
			flex: 0 1 100%;
			margin-right: 0.2em;
			display: block;
			font-family: $secondaryFontBold;
			font-weight: 800;
		}
		&__min-header {
			font-family: $secondaryFont;
			flex: 0 1 100%;
			font-size: 0.7em;
			text-align: right;
		}
	}
	&__row {
		display: flex;
		&__vehicle-count {
			// Set a fixed width because sometimes instead of a circle it will be a single bike number
			flex: 0 0 2em;
			height: 2em;
			margin-right: 0.5em;
			position: relative;
			display: flex;
			align-items: center; // ProgressArc
			svg {
				width: 100%;
				height: auto;
				circle:first-child {
					stroke-width: 1;
				}
			} // This is the numeral
			span {
				position: absolute;
				top: 0;
				left: 0;
				text-align: center;
				width: 100%;
				height: 100%;
				line-height: 2.3em; // Vertically align the number
				font-size: 0.9em;
			}
		}
		&__vehicle-type {
			flex: 0 0 2em;
			display: flex;
			align-items: center;
			padding: 0.2em;
			svg {
				width: 2em; // Do not set 100% for IE
				height: auto;
				max-height: 1em;
			}
		}
		&__agency-logo {
			flex: 0 0 1.5em;
			height: 100%;
			margin-right: 0.5em;
			display: flex;
			align-items: center;
			svg {
				height: 100%;
				width: 100%;
				max-height: 1em;
			}
		}
		&__docked-location {
			flex: 1 1 60%;
			padding-right: 0.5em;
			span {
				font-size: 0.8em;
				line-height: 1em;
				display: block;
			}
			&__name {
				padding-top: 0.25em;
			}
			&__vehicle-type-name {
				font-size: 0.8em;
				color: $tsDarkGray;
			}
			&__vehicle-count {
				font-size: 0.6em !important;
				color: $tsDarkGray;
				margin: 0.5em 0 0.5em 0;
			}
		}
		&__walk-time {
            flex: 0 0 12%; // Preserves prediction width of 3 chars max
			text-align: right;
			&__time {
				@include fontSize(18px);
			}
		}
	}
}