// @import '../../mixins';

.masstransit {
    &__row {
        list-style-type: none;
        width: 100%;

        @media screen and (max-width: 670px) {
            &__destination, &__crowding, &__prediction {
                font-size: 0.75em;
            }
        }

        &__inner {
            width: 100%;
            min-height: 1.8em; // Setting a baseline height vs using padding to give breathing room
            display: flex;
            align-items: center;
        }

        &__route {
            font-family: $secondaryFontBold;
            flex: 0 0 18%; // prevent cell collapse and enforce a proportion
            margin-right: 0.5em;

            font-size: 0.9em;

            line-height: 1.2em;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__route-color {
            flex: 0 0 0.25em;
            display: block;
            width: 0.25em; // Use ems so that the line doesnt thin out in higher zooms
            height: 1.2em;
            margin-right: 0.25em;
            border-radius: 1em;
        }

        &__destination {
            flex: 1 1 60%;
            padding: 0.25em 0 0.25em 0;

            &__name {
                line-height: 1.2em;
                font-size: 0.9em;
                hyphens: manual;
            }

            > span {
                display: block;
                padding-right: 0.5em; // prevents collision to prediction numeral
            }

            &__direction {
                font-size: 0.7em;
                font-family: $primaryFontBold;
                line-height: 1.2em;
                span {
                    margin-right: 0.5em;
                }
            }
        }

        &__crowding {
            flex: 1 0 auto;
        }

        &__prediction {
            flex: 0 0 auto;
        }

    }
}

// .flip {
//     overflow: hidden;
// }
