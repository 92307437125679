.theme-style-wrapper.theme--light {
    &.theme--actionfigure {
        &:not(.has-background-color) {
            background: #fff;
            .content-container__row {
                background: #fff;
            }
        }
        .screen-content__main,
        .screen-content__column {
            border-color: #aaa;
        }
        .border-element .piece {
            background: #aaa;
        }
        .mode-icon svg * {
            fill: #081754;
        }
        .visual-icon-stroke svg * {
            stroke: #081754;
        }
        .visual-icon-fill svg * {
            fill: #081754;
        }

        .dot {
            background: #081754;
        }
    }
}
